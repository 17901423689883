import React, { useMemo } from 'react';
import { Grid } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';
import dynamic from 'next/dynamic';
import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import Tagger from '@elbwalker/tagger';
import { Document } from '@contentful/rich-text-types';

import * as ColumnLayoutService from './ColumnLayoutService';
import { Image, ImagePresetSize, Alignment, Column } from './interfaces';

import { ALIGNMENT } from '~/shared/constants';
import dataSelector from '~/shared/util/data-selector';
import SectionHeader from '~/shared/components/SectionHeader';
import BackgroundImage from '~/shared/components/BackgroundImage';
import useViewportName from '~/shared/hooks/use-viewport-name';
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const ColumnLayoutStack = dynamic(
  () =>
    import(
      /* webpackChunkName: 'component-columnLayoutStack' */ './components/ColumnLayoutStack'
    ),
);
const ColumnLayoutSlider = dynamic(
  () =>
    import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: 'component-columnLayoutSlider' */ './components/ColumnLayoutSlider'
    ),
);

interface ColumnLayoutProps {
  headline?: string;
  styledHeadline?: Document;
  content?: Document;
  columns?: Column[];
  index?: number;
  desktopAlignment?: Alignment;
  tabletAlignment?: Alignment;
  mobileAlignment?: Alignment;
  headerAlignment?: Alignment;
  fullWidthButtons?: boolean;
  alignAllButtons?: boolean;
  splitLayout?: boolean;
  isSlider?: boolean;
  soloDesign?: Record<string, any>;
  stackOnTablet?: boolean;
  imagePresetSize?: ImagePresetSize;
  imagePresetAlignment?: Alignment;
  contentType?: string;
  name?: string;
  id?: string;
  taggingContext?: Record<string, any>;
  layoutBackgroundColorsCombo?: string;
  desktopBackgroundImage?: Image;
  tabletBackgroundImage?: Image;
  mobileBackgroundImage?: Image;
}

const sectionHeaderStyles = ({ theme }: { theme?: Theme }) => css`
  margin-bottom: ${theme.spacings.kilo};
  z-index: 3;
`;

const StyledSectionHeader = styled(SectionHeader)(sectionHeaderStyles);

const gridStyles = () => css`
  overflow: hidden;
`;

const StyledGrid = styled(Grid)(gridStyles);

const tagger = Tagger();

/**
 * Container for dynamically displaying columns
 */
function ColumnLayout({
  columns: originalColumns = [],
  headline = '',
  styledHeadline = { content: [], data: {}, nodeType: undefined },
  content,
  index,
  headerAlignment = ALIGNMENT.CENTER,
  desktopAlignment = ALIGNMENT.LEFT,
  tabletAlignment = ALIGNMENT.LEFT,
  mobileAlignment = ALIGNMENT.LEFT,
  fullWidthButtons,
  alignAllButtons,
  isSlider = false,
  soloDesign = {},
  stackOnTablet = false,
  imagePresetSize,
  imagePresetAlignment,
  taggingContext,
  layoutBackgroundColorsCombo,
  desktopBackgroundImage,
  tabletBackgroundImage,
  mobileBackgroundImage,
}: ColumnLayoutProps) {
  const { experiments } = useOptimizelyData();
  const columns = mapVariations(originalColumns, experiments);

  const viewport = useViewportName();

  const backgroundImage = ColumnLayoutService.getBackgroundImage(
    desktopBackgroundImage,
    tabletBackgroundImage,
    mobileBackgroundImage,
    viewport,
  );

  const columnSlides = isSlider
    ? ColumnLayoutService.getColumnSlides(columns, viewport)
    : null;

  const showSlider = isSlider && !isEmpty(columnSlides);

  const alignments = {
    desktopAlignment,
    tabletAlignment,
    mobileAlignment,
  };

  const imagePreset = { imagePresetSize, imagePresetAlignment };

  const trackingAttributes = useMemo(() => {
    if (taggingContext) {
      return {
        ...tagger.context({
          ...taggingContext,
          component: 'column_layout',
        }),
      };
    }

    return {
      ...tagger.context({ component: 'column_layout' }),
    };
  }, [taggingContext]);

  const isFirstSectionWithoutHeadline =
    index === 0 && !headline && isEmpty(content);

  return (
    <StyledGrid
      data-selector={dataSelector('section', 'columnLayout')}
      {...trackingAttributes}
    >
      <BackgroundImage {...backgroundImage} />
      <StyledSectionHeader
        headline={headline}
        content={content}
        alignment={headerAlignment}
        index={index}
        soloDesign={soloDesign}
        fullWidthHeadline
        styledHeadline={styledHeadline}
      />
      {showSlider ? (
        <ColumnLayoutSlider
          slides={columnSlides}
          alignments={alignments}
          fullWidthButtons={fullWidthButtons}
          alignAllButtons={alignAllButtons}
          index={index}
          imagePreset={imagePreset}
          isFirstSectionWithoutHeadline={isFirstSectionWithoutHeadline}
        />
      ) : (
        <ColumnLayoutStack
          columns={columns}
          alignments={alignments}
          fullWidthButtons={fullWidthButtons}
          alignAllButtons={alignAllButtons}
          index={index}
          stackOnTablet={stackOnTablet}
          imagePreset={imagePreset}
          layoutBackgroundColorsCombo={layoutBackgroundColorsCombo}
          isFirstSectionWithoutHeadline={isFirstSectionWithoutHeadline}
        />
      )}
    </StyledGrid>
  );
}

/**
 * @component
 */
export default ColumnLayout;
