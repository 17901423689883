import chunk from 'lodash/fp/chunk';
import { BLOCKS, Document } from '@contentful/rich-text-types';

import { Column, EmbeddedImage, Image } from './interfaces';

import { mapHeadingsContent } from '~/shared/components/RichText/util';
import { VIEWPORTS } from '~/shared/constants';
import { TYPES } from '~/shared/constants/sections';
import {
  FlattenedSys,
  FlattenedEmbeddedButton,
} from '~/shared/services/contentful';

/**
 * Adds some properties to the rich text nodes
 */

export function enrichColumn(props) {
  return (
    column: Column,
    splitLayout?: boolean,
  ): (EmbeddedImage | Column)[] => {
    const enrichedColumn = {
      ...column,
      content: {
        ...column.content,
        content: column.content.content.map((ctn) => ({
          ...ctn,
          ...props,
        })),
      },
    };
    const updatedHeadingsContent = mapHeadingsContent(
      enrichedColumn.content,
      'p',
    );
    enrichedColumn.content = updatedHeadingsContent;
    const media = splitLayout && enrichedColumn.content.content.shift();

    return [media, enrichedColumn];
  };
}

/**
 * Determines whether the component has a split layout and should render the image
 */
export function hasSplitLayoutForViewport(
  viewportName: string,
  columnData: Column = {},
): boolean {
  const firstElement = columnData?.content?.content?.[0];
  if (!firstElement) {
    return false;
  }

  const firstElementIsEmbedded =
    firstElement.nodeType === 'embedded-entry-block';
  if (!firstElementIsEmbedded) {
    return false;
  }

  const embeddedContentType = (firstElement?.data?.target as FlattenedSys)
    ?.contentType;
  const firstElementIsImageOrVideo =
    embeddedContentType === 'genericImage' ||
    embeddedContentType === 'showcaseEmbeddedVideo';
  if (!firstElementIsImageOrVideo) {
    return false;
  }

  if (viewportName === VIEWPORTS.MOBILE && columnData.splitLayout) {
    return true;
  }

  if (viewportName === VIEWPORTS.TABLET && columnData.splitLayoutTablet) {
    return true;
  }

  if (viewportName === VIEWPORTS.DESKTOP && columnData.splitLayoutDesktop) {
    return true;
  }

  return false;
}

function separateArray(
  columns: Column[],
  columnsPerSlide: number,
  maxColumns?: number,
): Column[][] {
  const extraCols = maxColumns ? maxColumns - columns.length : 0;
  const slides = [...columns];

  for (let i = 0; i < extraCols; i += 1) {
    slides.push({});
  }

  return chunk(columnsPerSlide, slides);
}

/**
 * Calculates the array of slides with columns
 */
export function getColumnSlides(
  columns: Column[],
  viewport: string,
): Column[][] {
  const numberOfColumns = columns.length;

  if (viewport === VIEWPORTS.MOBILE) {
    return separateArray(columns, 1);
  }

  if (viewport === VIEWPORTS.TABLET) {
    return numberOfColumns > 2 ? separateArray(columns, 2) : null;
  }

  if (numberOfColumns <= 3) {
    return null;
  }

  return separateArray(columns, 3, 6);
}

export function getBackgroundImage(
  desktop: Image,
  tablet: Image,
  mobile: Image,
  viewport: string,
): Image | undefined {
  if (!(desktop || tablet || mobile)) {
    return undefined;
  }

  if (viewport === VIEWPORTS.DESKTOP) {
    return desktop;
  }

  if (viewport === VIEWPORTS.TABLET) {
    return tablet;
  }

  if (viewport === VIEWPORTS.MOBILE) {
    return mobile;
  }

  return undefined;
}

// get Contentful ID of the first embedded button in RichText to align with buttons in other columns
export function getAlignButtonId(
  richtextContent: Document,
): string | undefined {
  const isRoot = richtextContent.nodeType === BLOCKS.DOCUMENT;
  if (isRoot) {
    const firstEmbeddedButton = richtextContent.content?.find(
      (content) =>
        content.nodeType === BLOCKS.EMBEDDED_ENTRY &&
        (content.data?.target as FlattenedSys)?.contentType ===
          TYPES.EMBEDDED_BUTTON,
    );

    return (firstEmbeddedButton?.data?.target as FlattenedEmbeddedButton)?.id;
  }

  return undefined;
}
